import { getCsrfTokenFromCookie } from "../helps/getToken";
import { $host, $authHost } from "./index";

const csrfToken = getCsrfTokenFromCookie('csrf_access_token');

export const getIdentList = async () => {
    try {
        const { data } = await $authHost.get(`api/verification`, {
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch getIdentList:", error);
    }
}

export const getIdentInfo = async (id) => {
    try {
        const { data } = await $authHost.get(`api/verification-info`, {
            params: {
                id: id,
            },
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch getIdentInfo:", error);
    }
}

export const resultCheck = async (id, result) => {
    try {
        const { data } = await $authHost.post(`api/result_check`, {
            id: id,
            result: result
        }, {
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch resultCheck:", error);
    }
}

export const fetchChips = async () => {
    try {
        const { data } = await $authHost.get(`api/chip-list`, {
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch chips:", error);
    }
}

export const fetchChipItem = async (id) => {
    try {
        const { data } = await $authHost.get(`api/chip-item`, {
            params: {
                id: id
            },
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch chips:", error);
    }
}

export const fetchChipCompletes = async (id, type, status) => {
    try {
        const { data } = await $authHost.post(`api/chip-completes`, {
            order_id: id,
            type: type,
            status: status
        }, {
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch chips:", error);
    }
}

export const fetchAuth = async (phone, password) => {
    try {
        const { data } = await $host.post(`api/auth_admin`, {
            phone: phone,
            password: password
        }, {
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch code:", error);
    }
}

export const checkCode = async (code) => {
    try {
        const { data } = await $authHost.post(`api/check_code`, {
            code: code
        }, {
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch code:", error);
        throw error
    }
}

export const fetchChipsHistory = async (page, limit) => {
    try {
        const { data } = await $authHost.get(`api/get_history`, {
            params: {
                limit: limit,
                page: page
            },
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch chips:", error);
    }
}

export const fetchChipsHistoryItem = async (id) => {
    try {
        const { data } = await $authHost.get(`api/get_history_item`, {
            params: {
                id: id
            },
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch chips:", error);
    }
}

export const fetchAdmins = async (page, limit) => {
    try {
        const { data } = await $authHost.get(`api/get_admins`, {
            params: {
                limit: limit,
                page: page
            },
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch chips:", error);
    }
}

export const changeAdmin = async (id, status, password) => {
    try {
        const { data } = await $authHost.post(`api/change_admin`, {
            id: id,
            status: status,
            password: password
        }, {
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch chips:", error);
    }
}

export const fetchSearch = async (type, search) => {
    try {
        const { data } = await $authHost.get(`api/search`, {
            params: {
                type: type,
                search: search,
            },
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch search:", error);
    }
}

export const checkAuth = async () => {
    try {
        const { data } = await $authHost.get(`api/check_auth`, {
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch checkAuth:", error);
        throw error;
    }
}

export const getSettingInfo = async () => {
    try {
        const { data } = await $authHost.get(`api/config`, {
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch getSettingInfo:", error);
    }
}

export const changeSettingInfo = async (info) => {
    try {
        const { data } = await $authHost.post(`api/config_update`, {
            info: info
        }, {
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch changeSettingInfo:", error);
    }
}

export const getStatuses = async (page, limit, search) => {
    try {
        const { data } = await $authHost.get(`api/if_status`, {
            params: {
                page: page,
                limit: limit,
                search: search
            },
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch getStatuses:", error);
    }
}

export const getPayments = async (page, limit) => {
    try {
        const { data } = await $authHost.get(`api/list-payment-application`, {
            params: {
                page: page,
                limit: limit
            },
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch getPayments:", error);
    }
}

export const acceptPayment = async (id, status) => {
    try {
        const { data } = await $authHost.post(`/api/condition-payment-application`, {
            id: id,
            status: status
        }, {
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch acceptPayment:", error);
    }
}

export const refferalSave = async (id, percent) => {
    try {
        const { data } = await $authHost.post(`/api/add/referal`, {
            id: id,
            percent: percent
        }, {
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch refferalSave:", error);
    }
}

export const refferalDelete = async (id) => {
    console.log(id);
    try {
        const { data } = await $authHost.post(`api/delete/referal`, {
            id: id
        }, {
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch refferalDelete:", error);
    }
}

export const searchRefferal = async (id) => {
    console.log(id);
    try {
        const { data } = await $authHost.post(`api/search/referal`, {
            id: id
        }, {
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch searchRefferal:", error);
    }
}

export const getProfile = async (id) => {
    try {
        const { data } = await $authHost.get(`api/info_ref`, {
            params: {
                id: id
            },
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch getProfile:", error);
    }
}

export const getAllUsers = async (page, limit, search) => {
    try {
        const { data } = await $authHost.get(`api/all_users`, {
            params: {
                page: page,
                limit: limit,
                search: search
            },
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch getAllUsers:", error);
    }
}

export const getUserInfo = async (id, page, limit, search) => {
    try {
        const { data } = await $authHost.get(`api/user_info`, {
            params: {
                user_id: id,
                page: page,
                limit: limit,
                search: search
            },
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch getUserInfo:", error);
    }
}

export const goOutputCash = async (outputCash) => {
    try {
        const { data } = await $authHost.post(`api/go_out_referral`, {
            user_id: outputCash.id,
            bank: outputCash.bank,
            number_card: outputCash.card,
            sum: outputCash.sum
        }, {
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch goOutputCash:", error);
    }
}

export const getStatistics = async () => {
    try {
        const { data } = await $authHost.get(`api/statistic`, {
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch getStatistics:", error);
    }
}

export const createApplication = async (data) => {
    try {
        const { data: result } = await $authHost.post(`/api/go_out_admin`, data, {
            withCredentials: true
        });
        return result;
    } catch (error) {
        console.error("Failed to fetch CreateApplication:", error);
    }
}

export const getApplications = async (nextPage, limit) => {
    try {
        const { data } = await $authHost.get(`/api/config_application`, {
            params: {
                page: nextPage,
                limit: limit
            },
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch getApplications:", error);
    }
}

export const deleteApplication = async (id) => {
    try {
        const { data } = await $authHost.post(`/api/delete_AD`, {
            id: id
        }, {
            withCredentials: true
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch deleteApplication:", error);
    }
}
