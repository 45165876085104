import { Routes, Route, useNavigate } from 'react-router-dom'
import { superAdminRoutes, adminRoutes, referalRoutes } from '../../routes';
import Auth from '../../pages/Auth/Auth';
import { useSelector } from 'react-redux';

function AppRouter() {

    const navigate = useNavigate();
    const user = useSelector(state => state.user);

    if (!user) {
        return navigate('/authorization');
    }

    return (
        <Routes>
            <Route path="/authorization" element={<Auth />} />

            {user.role === 'admin' && adminRoutes.map(({ path, Component }) => (
                <Route key={path} path={path} element={<Component />} />
            ))}

            {user.role === 'super-admin' && superAdminRoutes.map(({ path, Component }) => (
                <Route key={path} path={path} element={<Component />} />
            ))}

            {user.role === 'referal' && referalRoutes.map(({ path, Component }) => (
                <Route key={path} path={path} element={<Component />} />
            ))}

        </Routes>
    )
}

export default AppRouter