import React, { useMemo } from 'react';
import styles from './Sidebar.module.css';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function SidebarLink({ to, active, children }) {

    const navigate = useNavigate();

    const logOut = () => {
        navigate('/authorization');
    }

    return (
        <li className={active ? styles.sidebar__link_active : styles.sidebar__link} onClick={() => to === '/authorization' ? logOut() : navigate(to)}>
            <a className={styles.sidebar__text} >
                {children}
            </a>
        </li>
    );
}

function Sidebar() {
    const { pathname } = useLocation();
    const user = useSelector(state => state.user);

    const links = useMemo(() => [
        { to: '/profile', name: 'Профиль', weight: 1, hideForAdmin: true },
        { to: '/', name: 'Заявки на идентификацию', weight: 2 },
        { to: '/admin-chips', name: 'Перевод фишек', weight: 2 },
        { to: '/admin-list', name: 'Добавить админа', weight: 3 },
        { to: '/create-application', name: 'Создать заявку', weight: 3 },
        { to: '/statistics', name: 'Статистика', weight: 3 },
        { to: '/people-list', name: 'Пользователи', weight: 3 },
        { to: '/pay-before', name: 'Проверка оплаты', weight: 2 },
        // { to: '/chip-history', name: 'История фишек', weight: 3 },
        { to: '/settings', name: 'Настройки', weight: 3 },
        { to: '/authorization', name: 'Выход', weight: 1 },
    ], []);

    return (
        <div className={styles.sidebar}>
            <h2>ADMIN PANEL</h2>
            <ul className={styles.sidebar__links}>
                {links.map((link, index) => {
                    const shouldHide = link.hideForAdmin && user.weight >= 2;
                    return (
                        user.weight >= link.weight && !shouldHide && (
                            <SidebarLink
                                key={link.to + index}
                                to={link.to}
                                active={pathname === link.to}
                            >
                                {link.name}
                            </SidebarLink>
                        )
                    );
                })}
            </ul>
        </div>
    );
}

export default Sidebar;

