import React, { useEffect, useState } from 'react';
import { fetchChipsHistoryItem } from '../../http/admin';
import { IoMdArrowBack } from "react-icons/io";
import styles from './HistoryItem.module.css';
import Sidebar from '../Sidebar/Sidebar';
import Loader from '../Loader/Loader';

function HistoryItem({ id, setOpenCard }) {
    const [chip, setChip] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getChipHistoryItem = async () => {
            setLoading(true);
            const data = await fetchChipsHistoryItem(id);
            setChip(data);
            setLoading(false);
        };
        getChipHistoryItem();
    }, [id]);

    return (
        <>
            {/* <Sidebar /> */}
            <section className={styles.section}>
                <IoMdArrowBack onClick={() => setOpenCard(false)} className={styles.back} />
                <h2 className={styles.title}>История перевода</h2>

                {loading ? (
                    <div className={styles.loader}><Loader /></div>
                ) : (
                    <>
                        <div className={styles.container}>
                            <div className={styles.detail}>
                                <span>Айди перевода:</span> {chip._id}
                            </div>
                            <div className={styles.detail}>
                                <span>Количество к покупке:</span> {chip.amount}
                            </div>
                            <div className={styles.detail}>
                                <span>Способ оплаты:</span> {chip.method_pay}
                            </div>
                            <div className={styles.detail}>
                                <span>Покер Room:</span> {chip.pokerRoom}
                            </div>
                            <div className={styles.detail}>
                                <span>Куда:</span> {chip.where_convert}
                            </div>
                            <div className={styles.detail}>
                                <span>Дата:</span> {chip.purchase_date}
                            </div>
                            <div className={styles.detail}>
                                <span>Сумма:</span> {chip.sum} ₽
                            </div>
                        </div>

                        <div className={styles.container}>
                            <p className={styles.text}>{chip.status ? 'ПЕРЕВЕДЕНО' : 'ОТКЛОНЕНО'}</p>
                        </div>
                    </>
                )}
            </section>
        </>
    );
}

export default HistoryItem;
