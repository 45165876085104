import React, { useEffect, useState } from 'react';
import styles from './AcceptIdentification.module.css';
import { getIdentInfo, resultCheck } from '../../http/admin';
import Loader from '../../components/Loader/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import { IoMdArrowBack, IoMdClose } from "react-icons/io";
import { useSnackbar } from 'notistack';

function AcceptIdentification() {
    const { id } = useParams();
    const [media, setMedia] = useState(null);
    const [userData, setUserData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        getUserInfo(id);
    }, [id]);

    const getUserInfo = async (id) => {
        setLoading(true);
        const data = await getIdentInfo(id);
        setMedia(data.media);
        setUserData(data.data);
        setLoading(false);
    };

    const handleResult = async (info) => {
        enqueueSnackbar('Обработка...', { variant: 'info' });
        const data = await resultCheck(userData.id, info);
        if (data.message) {
            enqueueSnackbar(data.message, { variant: 'success' });
            navigate('/');
        } else {
            enqueueSnackbar(data.error, { variant: 'error' });
        }
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <>
            <Sidebar />
            <section className={styles.container}>
                <IoMdArrowBack onClick={() => navigate(-1)} className={styles.back} />
                <h2 className={styles.title}>
                    Идентификация
                </h2>
                {loading ? (
                    <div className={styles.loader}><Loader /></div>
                ) : (
                    <>
                        <div className={styles.media}>
                            <div className={styles.media__wrapper}>
                                <img
                                    className={styles.media__img}
                                    src={media.photo}
                                    alt=""
                                    onClick={toggleModal}
                                />
                            </div>
                            <div className={styles.media__wrapper}>
                                <video
                                    className={styles.media__video}
                                    controls
                                    onError={(e) => console.error("Video Error:", e)}
                                >
                                    <source src={media.video} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>

                        <div className={styles.info}>

                            <div className={styles.fields}>

                                <div className={styles.field}>
                                    <p className={styles.text}>Фамилия:</p>
                                    <p className={styles.text}>{userData.lastName}</p>
                                </div>

                                <div className={styles.field}>
                                    <p className={styles.text}>Имя:</p>
                                    <p className={styles.text}>{userData.firstName}</p>
                                </div>

                                <div className={styles.field}>
                                    <p className={styles.text}>Отчество:</p>
                                    <p className={styles.text}>{userData.middleName}</p>
                                </div>

                                <div className={styles.field}>
                                    <p className={styles.text}>Покер рум:</p>
                                    <p className={styles.text}>{userData.pokerRoom}</p>
                                </div>
                            </div>

                            <div className={styles.buttons}>
                                <div className={styles.btn__wrapper}>
                                    <button onClick={() => handleResult(false)} className={`${styles.btn} ${styles.btn__decline}`}>Отклонить</button>
                                    <button onClick={() => handleResult(true)} className={`${styles.btn} ${styles.btn__accept}`}>Принять</button>
                                </div>
                            </div>
                        </div>
                    </>
                )}

            </section>

            {isModalOpen && (
                <div className={styles.modal} onClick={toggleModal}>
                    <IoMdClose className={styles.modalClose} onClick={toggleModal} />
                    <img src={media.photo} alt="" className={styles.modalContent} />
                </div>
            )}
        </>
    )
}

export default AcceptIdentification