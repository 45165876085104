import React, { useEffect, useState } from 'react';
import styles from './PayBefore.module.css';
import { acceptPayment, getPayments } from '../../http/admin';
import Loader from '../../components/Loader/Loader';
import Sidebar from '../../components/Sidebar/Sidebar';
import InfiniteScroll from 'react-infinite-scroll-component';
import { enqueueSnackbar } from 'notistack';

function PayBefore() {
    const [orderInfo, setOrderInfo] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const [isEnd, setIsEnd] = useState(false);

    const fetchStatus = async (currentPage) => {
        try {
            const data = await getPayments(currentPage, limit);
            if (currentPage === 1) {
                setOrderInfo(data.data);
            } else {
                setOrderInfo(prev => [...prev, ...data.data]);
            }
            setIsEnd(data.pagination.total_count <= orderInfo.length + data.data.length);
        } catch (error) {
            console.error('Error fetching payments:', error);
            enqueueSnackbar('Произошла ошибка при загрузке данных!', { variant: 'error' });
        }
    };

    useEffect(() => {
        fetchStatus(page);
    }, [page]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchStatus(page);
        }, 20000);

        return () => clearInterval(intervalId);
    }, [isEnd]);

    const handleClick = async (id, status) => {
        enqueueSnackbar('Обработка!', { variant: 'info' });
        try {
            await acceptPayment(id, status);
            setOrderInfo(orderInfo.filter(item => item._id !== id));
            enqueueSnackbar('Успешно!', { variant: 'success' });
        } catch (error) {
            console.error('Error accepting payment:', error);
            enqueueSnackbar('Произошла ошибка!', { variant: 'error' });
        }
    }

    return (
        <>
            <Sidebar />
            <section className={styles.container}>
                <h2 className={styles.title}>
                    Проверка оплаты от продавца
                </h2>
                <div className={styles.list__wrapper} id='scrollableDiv' style={{ height: '80vh', overflow: 'auto' }}>
                    <ul className={styles.list}>
                        <InfiniteScroll
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                                listStyle: 'none',
                                gap: '10px',
                            }}
                            scrollableTarget="scrollableDiv"
                            next={() => setPage(prevPage => prevPage + 1)}
                            dataLength={orderInfo.length}
                            hasMore={!isEnd}
                            loader={<Loader />}>
                            {orderInfo.map(item =>
                                <div className={styles.list__item} key={item._id}>
                                    <div className={styles.list__item__wrapper}>
                                        <div className={styles.list__item__icon}>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="Navbar_profile__icon__-vWlz" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg>
                                        </div>
                                        <div className={styles.list__item__info}>
                                            <div className={styles.info__wrapper}>
                                                <p>Айди: {item._id}</p>
                                                <p>Покер-рум: {item.pokerRoom}</p>
                                                <p>Курс: {item.course}</p>
                                                <p>Макс-Мин: {item.max} - {item.min}</p>
                                                <p>Кол-во: {item.quantity}</p>
                                                <p>Сумма: {item.total_pay} ₽</p>
                                                <p>Время: {item.time}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.btn__wrapper}>
                                        <button className={`${styles.btn} ${styles.btn__decline}`} onClick={() => handleClick(item._id, false)}>Отменить</button>
                                        <button className={`${styles.btn} ${styles.btn__accept}`} onClick={() => handleClick(item._id, true)}>Подтвердить</button>
                                    </div>
                                </div>
                            )}
                        </InfiniteScroll>
                        {/* {loading && <div className={styles.loader}><Loader /></div>} */}
                        {orderInfo.length === 0 && <div className={styles.no__data}><p className={styles.list__item__name}>Нет заявок</p></div>}
                    </ul>
                </div>
            </section>
        </>
    )
}

export default PayBefore;
