import React, { useEffect, useState } from 'react';
import { fetchChipsHistory } from '../../http/admin';
import styles from './ChipHistory.module.css';
import Sidebar from '../../components/Sidebar/Sidebar';
import Loader from '../../components/Loader/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import HistoryItem from '../../components/HistoryItem/HistoryItem';

function ChipHistory() {
    const [chips, setChips] = useState([]);
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const [isEnd, setIsEnd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openCard, setOpenCard] = useState({ id: null, open: false });

    useEffect(() => {
        getChipsHistory();
    }, [page]);

    const getChipsHistory = async () => {
        setLoading(true);
        try {
            const data = await fetchChipsHistory(page, limit);
            setChips(prevChips => [...prevChips, ...data.data]);
            setIsEnd(data.pagination.total_count <= chips.length + data.data.length);
        } catch (error) {
            console.error('Failed to fetch chips history:', error);
            setIsEnd(true);
        }
        setLoading(false);
    };

    if (!chips.length && loading) {
        return <section className={styles.container__loader}>
            <Sidebar />
            <div className={styles.loader}><Loader /></div>
        </section>
    }

    return (
        <>
            <Sidebar />
            <div className={styles.container}>
                <h2 className={styles.title}>История переводов</h2>
                <div className={styles.chips} id='scrollableDiv'>
                    <InfiniteScroll
                        style={{ overflow: 'auto' }}
                        scrollableTarget="scrollableDiv"
                        next={() => setPage(prevPage => prevPage + 1)}
                        dataLength={chips.length}
                        hasMore={!isEnd}
                        loader={<div className={styles.loader__text}>
                            <p>Загрузка...</p>
                        </div>}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Комната</th>
                                    <th>Способ оплаты</th>
                                    <th>Кол-во</th>
                                    <th>Сумма</th>
                                    <th>Дата</th>
                                </tr>
                            </thead>
                            <tbody>
                                {chips.map(item => (
                                    <tr key={item._id} onClick={() => setOpenCard({ id: item._id, open: true })}>
                                        <td>{item._id}</td>
                                        <td>{item.pokerRoom}</td>
                                        <td>{item.method_pay}</td>
                                        <td>{item.amount}</td>
                                        <td>{item.sum}</td>
                                        <td>{item.purchase_date}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                    {/* {loading && <div className={styles.loader}><Loader /></div>} */}
                    {chips.length === 0 && !loading && <div><p className={styles.no__data}>Пусто...</p></div>}
                    {openCard.open && <HistoryItem id={openCard.id} setOpenCard={setOpenCard} />}
                </div>
            </div>
        </>
    )
}

export default ChipHistory;
