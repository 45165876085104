
import AcceptList from "./pages/AcceptList/AcceptList"
import ChipItem from "./pages/ChipItem/ChipItem"
import AcceptIdentification from "./pages/AcceptIdentification/AcceptIdentification"
import Chips from "./pages/Chips/Chips"

import {
    ADMIN_IDENTIFICATION,
    ADMIN_IDENTIFICATION__LIST,
    ADMIN_CHIPS,
    ADMIN_CHIP_ITEM,
    CHIP_HISTORY,
    ADD_ADMIN,
    ADMIN_LIST,
    SETTINGS,
    PAY_BEFORE,
    PROFILE,
    PEOPLE__LIST,
    PEOPLE__ITEM,
    STATISTICS,
    CREATE_APPLICATION
} from "./utils/consts"
import ChipHistory from "./pages/ChipHistory/ChipHistory"
import AddAdmin from "./components/AddAdmin/AddAdmin"
import AdminList from "./pages/AdminList/AdminList"
import Settings from "./pages/Settings/Settings"
import PayBefore from "./pages/PayBefore/PayBefore"
import Profile from "./pages/Profile/Profile"
import PeopleList from "./pages/PeopleList/PeopleList"
import PeopleItem from "./pages/PeopleItem/PeopleItem"
import Statistics from "./pages/Statistics/Statistics"
import CreateApplication from "./pages/CreateApplication/CreateApplication"

export const referalRoutes = [
    {
        path: PROFILE,
        Component: Profile
    }
]

export const superAdminRoutes = [
    {
        path: ADMIN_IDENTIFICATION,
        Component: AcceptIdentification
    },
    {
        path: ADMIN_IDENTIFICATION__LIST,
        Component: AcceptList
    },
    {
        path: ADMIN_CHIPS,
        Component: Chips
    },
    {
        path: ADMIN_CHIP_ITEM,
        Component: ChipItem
    },
    {
        path: CHIP_HISTORY,
        Component: ChipHistory
    },
    {
        path: ADMIN_LIST,
        Component: AdminList
    },
    {
        path: ADD_ADMIN,
        Component: AddAdmin
    },
    {
        path: SETTINGS,
        Component: Settings
    },
    {
        path: PAY_BEFORE,
        Component: PayBefore
    },
    {
        path: PROFILE,
        Component: Profile
    },
    {
        path: PEOPLE__LIST,
        Component: PeopleList
    },
    {
        path: PEOPLE__ITEM,
        Component: PeopleItem
    },
    {
        path: STATISTICS,
        Component: Statistics
    },
    {
        path: CREATE_APPLICATION,
        Component: CreateApplication
    }
]

export const adminRoutes = [
    {
        path: ADMIN_IDENTIFICATION,
        Component: AcceptIdentification
    },
    {
        path: ADMIN_IDENTIFICATION__LIST,
        Component: AcceptList
    },
    {
        path: ADMIN_CHIPS,
        Component: Chips
    },
    {
        path: ADMIN_CHIP_ITEM,
        Component: ChipItem
    },
    {
        path: CHIP_HISTORY,
        Component: ChipHistory
    },
    {
        path: ADMIN_LIST,
        Component: AdminList
    },
    {
        path: ADD_ADMIN,
        Component: AddAdmin
    },
    {
        path: SETTINGS,
        Component: Settings
    },
    {
        path: PAY_BEFORE,
        Component: PayBefore
    },
    {
        path: PROFILE,
        Component: Profile
    },
    {
        path: PEOPLE__LIST,
        Component: PeopleList
    },
    {
        path: PEOPLE__ITEM,
        Component: PeopleItem
    }
]