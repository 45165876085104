import React, { useEffect, useState } from 'react';
import styles from './CreateApplication.module.css';
import Sidebar from '../../components/Sidebar/Sidebar';
import { getSettingInfo, createApplication as createApplicationAPI, getApplications, deleteApplication } from '../../http/admin';
import { enqueueSnackbar } from 'notistack';
import InfiniteScroll from 'react-infinite-scroll-component';

const CreateApplication = () => {
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [loadingScroll, setLoadingScroll] = useState(false);
    const [methods, setMethods] = useState([]);
    const [listings, setListings] = useState([]);
    const [pokerRooms, setPokerRooms] = useState([]);
    const [page, setPage] = useState(1);
    const [limit] = useState(100); // Limiting for pagination
    const [hasMore, setHasMore] = useState(true);
    const [application, setApplication] = useState({
        sum: 0,
        minSum: 5, // Минимальное значение 5
        course: 0,
        quantity: 0,
        methodPay: '',
        pokerRoom: '',
        paymentDestination: '',
        cardNumber: '' // Новое поле для карты
    });
    const [isFetching, setIsFetching] = useState(false); // New state to prevent multiple requests

    useEffect(() => {
        const fetchInitialData = async () => {
            const data = await getApplications(1, limit);
            setMethods(data.config.methods);
            setListings(Array.isArray(data.listings) ? data.listings : []);
            setPokerRooms(data.config.pokerRoom);
            setHasMore(data.listings.length === limit);
        };
        fetchInitialData();
    }, [limit]);

    const handleSubmit = async () => {
        const { sum, minSum, course, quantity, methodPay, pokerRoom, paymentDestination, cardNumber } = application;

        // Валидация минимальной суммы
        if (minSum < 5) {
            enqueueSnackbar('Минимальная сумма должна быть не менее 5', { variant: 'error' });
            return;
        }

        // Проверка обязательности поля cardNumber если выбрана "Авто подстановка"
        if (paymentDestination === 'manual' && !cardNumber) {
            enqueueSnackbar('Пожалуйста, введите номер карты', { variant: 'error' });
            return;
        }

        if (sum && minSum && course && quantity && methodPay && pokerRoom && paymentDestination) {
            setLoadingCreate(true);
            enqueueSnackbar('Создание заявки...', { variant: 'info' });
            const data = await createApplicationAPI(application);
            if (data.message === 'Success!') {
                enqueueSnackbar('Заявка создана!', { variant: 'success' });
                setListings(prevListings => [...prevListings, data.data]);
                resetApplicationForm();
            } else {
                enqueueSnackbar('Произошла ошибка', { variant: 'error' });
            }
            setLoadingCreate(false);
        }
    };

    const resetApplicationForm = () => {
        setApplication({
            sum: 0,
            minSum: 5, // Минимальное значение по умолчанию 5
            course: 0,
            quantity: 0,
            methodPay: '',
            pokerRoom: '',
            paymentDestination: '',
            cardNumber: '' // Сброс карты
        });
    };

    const fetchMoreListings = async () => {
        if (isFetching) return; // Check if already fetching
        setLoadingScroll(true);
        setIsFetching(true); // Set fetching flag
        try {
            const nextPage = page + 1;
            const data = await getApplications(nextPage, limit);
            setListings(prevListings => [...prevListings, ...data.listings]);
            setPage(nextPage);
            setHasMore(data.listings.length === limit);
        } catch (error) {
            enqueueSnackbar('Произошла ошибка при загрузке заявок', { variant: 'error' });
        }
        setLoadingScroll(false);
        setIsFetching(false); // Reset fetching flag
    };

    const deleteApplicationById = async (id) => {
        setListings(prevListings => prevListings.filter(listing => listing._id !== id));
        enqueueSnackbar('Обработка...', { variant: 'info' });
        const data = await deleteApplication(id);
        if (data.message === 'Success') {
            enqueueSnackbar('Заявка удалена!', { variant: 'success' });
            setListings(prevListings => prevListings.filter(listing => listing._id !== id));
        } else {
            enqueueSnackbar('Произошла ошибка', { variant: 'error' });
        }
    };

    return (
        <>
            <Sidebar />
            <section className={styles.container}>
                <h2 className={styles.title}>Создание заявки</h2>
                <div className={styles.formContainer}>
                    <form className={styles.form}>
                        {['sum', 'minSum', 'course', 'quantity'].map(field => (
                            <div className={styles.field} key={field}>
                                <label htmlFor={field}>{field === 'sum' ? 'Сумма' : field === 'minSum' ? 'Минимальная сумма' : field === 'course' ? 'Курс' : 'Количество'}</label>
                                <input
                                    type="number"
                                    min={field === 'minSum' ? 5 : undefined} // Ограничение на минимальную сумму
                                    placeholder={field === 'sum' ? 'Сумма' : field === 'minSum' ? 'Минимальная сумма (минимум 5)' : field === 'course' ? 'Курс' : 'Количество'}
                                    value={application[field]}
                                    onChange={(e) => setApplication({ ...application, [field]: e.target.value })}
                                />
                            </div>
                        ))}
                        <div className={styles.field}>
                            <label htmlFor="methodPay">Метод оплаты</label>
                            <select
                                value={application.methodPay}
                                onChange={(e) => setApplication({ ...application, methodPay: e.target.value })}
                            >
                                <option value="">Выберите метод оплаты</option>
                                {methods.map((method) => (
                                    <option key={method.methodName} value={method.methodName}>{method.methodName}</option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.field}>
                            <label htmlFor="pokerRoom">Покер рум</label>
                            <select
                                value={application.pokerRoom}
                                onChange={(e) => setApplication({ ...application, pokerRoom: e.target.value })}
                            >
                                <option value="">Выберите покер рум</option>
                                {pokerRooms.map((room) => (
                                    <option key={room.name} value={room.name}>{room.name}</option>
                                ))}
                            </select>
                        </div>

                        {/* Новый выпадающий список для "Куда оплачивать?" */}
                        <div className={styles.field}>
                            <label htmlFor="paymentDestination">Куда оплачивать?</label>
                            <select
                                value={application.paymentDestination}
                                onChange={(e) => setApplication({ ...application, paymentDestination: e.target.value })}
                            >
                                <option value="">Выберите вариант</option>
                                <option value="manual">Вписать карту вручную</option>
                                <option value="auto">Автоматическая подстановка</option>
                            </select>
                        </div>

                        {/* Условное отображение поля для ввода карты */}
                        {application.paymentDestination === 'manual' && (
                            <div className={styles.field}>
                                <label htmlFor="cardNumber">Номер карты</label>
                                <input
                                    type="text"
                                    placeholder="Введите номер карты"
                                    value={application.cardNumber}
                                    onChange={(e) => setApplication({ ...application, cardNumber: e.target.value })}
                                />
                            </div>
                        )}

                        <button className={styles.button} type="button" onClick={handleSubmit}>
                            {loadingCreate ? 'Загрузка...' : 'Создать заявку'}
                        </button>
                    </form>
                    <div className={styles.listingsContainer} id="scrollableDiv">
                        <InfiniteScroll
                            dataLength={listings.length}
                            next={fetchMoreListings}
                            hasMore={hasMore}
                            loader={<h4>Загрузка...</h4>}
                            scrollableTarget="scrollableDiv"
                        >
                            {listings.length > 0 ? (
                                <ul className={styles.listings}>
                                    {listings.map((listing) => (
                                        <li className={styles.listing} key={listing._id}>
                                            <div>
                                                <h3>Заявка: {listing.number_listing}</h3>
                                                <p>Курс: {listing.course}</p>
                                                <p>Кол-во: {listing.quantity}</p>
                                                <p>Макс. сумма: {listing.max}</p>
                                                <p>Мин. сумма: {listing.min}</p>
                                                <p>Покер-рум: {listing.pokerRoom}</p>
                                            </div>
                                            <button className={styles.buttonDelete} onClick={() => deleteApplicationById(listing._id)}>Удалить</button>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                !loadingScroll && listings.length !== 0 && <p>Нет доступных заявок</p>
                            )}
                        </InfiniteScroll>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CreateApplication;
