import React, { useEffect, useState } from 'react'
import styles from './AddAdmin.module.css';
import Sidebar from '../Sidebar/Sidebar';
function AddAdmin({ user }) {

    return (
        <section className={styles.section}>

            <div className={styles.container}>
                <h2 className={styles.title}>
                    Добавить/Удалить администратора
                </h2>

                <div className={styles.field}>
                    <label htmlFor="">Айди аккаунта</label>
                    <input className={styles.input} placeholder='Айди' type="text" />
                </div>

                <div className={styles.buttons}>
                    <button className={styles.button} >Удалить</button>
                </div>

            </div>
        </section>
    )
}

export default AddAdmin