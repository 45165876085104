import React, { useEffect, useState } from 'react';
import styles from './PeopleList.module.css';
import Loader from '../../components/Loader/Loader';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import { getAllUsers } from '../../http/admin';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IoMdArrowBack } from 'react-icons/io';

const PeopleList = () => {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(100);
    const [hasMore, setHasMore] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        fetchUsers();
    }, [page, search]);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const data = await getAllUsers(page, limit, search);
            if (page === 1) {
                setUsers(data.data);
            } else {
                setUsers(prev => [...prev, ...data.data]);
            }
            setHasMore(data.pagination.total_count > users.length + data.data.length);
        } catch (error) {
            console.error('Failed to fetch users:', error);
        }
        setLoading(false);
    };

    return (
        <>
            <Sidebar />
            <section className={styles.container}>
                <h2 className={styles.title}>Информация по пользователям</h2>
                <div className={styles.list__wrapper}>
                    <div className={styles.input__wrapper}>
                        <input
                            type="text"
                            className={styles.input}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder='Поиск по ФИО и айди'
                        />
                        {/* <IoMdArrowBack className={styles.enter} onClick={handleSearchClick} /> */}
                    </div>
                    <div className={styles.list} id='scrollableDiv'>
                        <InfiniteScroll
                            style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '10px' }}
                            scrollableTarget="scrollableDiv"
                            next={() => setPage(prevPage => prevPage + 1)}
                            dataLength={users.length}
                            hasMore={hasMore}
                            loader={<Loader />}
                        >
                            {users.map((user) => (
                                <div className={styles.list__item} key={user.id} onClick={() => navigate(`/people-item/${user.id}`)}>
                                    <div className={styles.list__item__icon}>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="Navbar_profile__icon__-vWlz" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg>
                                    </div>
                                    <div className={styles.list__item__info}>
                                        <p>ФИО: {user.full_name || 'Неизвестно'}</p>
                                        <p>Айди: {user.id}</p>
                                    </div>
                                </div>
                            ))}
                        </InfiniteScroll>
                        {loading && <div className={styles.loader}><Loader /></div>}
                    </div>
                </div>
            </section>
        </>
    );
};

export default PeopleList;
