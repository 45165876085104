import React, { useEffect, useState } from 'react'
import styles from './Chips.module.css'
import { useNavigate } from 'react-router-dom'
import { fetchChips } from '../../http/admin';
import Loader from '../../components/Loader/Loader';
import Sidebar from '../../components/Sidebar/Sidebar';

function Chips() {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const getList = async () => {
            setLoading(true);
            try {
                const data = await fetchChips();
                setList(data.data);
            } catch (error) {
                console.error('Error fetching chips:', error);
            }
            setLoading(false);
        };

        getList();

        const intervalId = setInterval(getList, 20000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <Sidebar />
            <div className={styles.container}>
                <h2 className={styles.title}>Список заявок (Фишки)</h2>
                <div className={styles.chips}>
                    {loading && <div className={styles.loader}><Loader /></div>}
                    <ul className={styles.list__wrapper} id="scrollableDiv">
                        {!loading && list.map(item => (
                            <li className={`${styles.list__item} ${item.type === 'go_out_referral' ? styles.list__item__info__referral : item.type === 'delete' ? styles.list__item__info__delete : ''}`}
                                key={item.id} onClick={() => navigate(`/admin-chip-item/${item._id}/${item.type || null}`)}>
                                <div className={styles.list__item__icon}>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="Navbar_profile__icon__-vWlz" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path>
                                    </svg>
                                </div>
                                <div className={styles.list__item__info__wrapper}>
                                    <div className={styles.list__item__sub__info}>
                                        {item.type === 'go_out_referral' && (
                                            <div className={styles.list__item__info}>
                                                <h2 className={styles.list__item__title}>Заявка от реферала!</h2>
                                                <p>Банк: {item.bank}</p>
                                                <p>Номер карты: {item.number_card}</p>
                                                <p>Сумма: {item.sum}</p>
                                                <p>Дата: {item.purchase_date}</p>
                                            </div>
                                        )}
                                        {item.type === 'delete' && (
                                            <div className={styles.list__item__info}>
                                                <h2 className={styles.list__item__title}>Отмена заявки!</h2>
                                                <p>Покер-рум: {item.pokerRoom}</p>
                                                <p>Логин: {item.login}</p>
                                                <p>Сумма: {item.sum}</p>
                                            </div>
                                        )}
                                        {item.type === 'check_pay' && (
                                            <div className={styles.list__item__info}>
                                                <h2 className={styles.list__item__title}>Покупка на карту админа!</h2>
                                                <p>Покер-рум: {item.pokerRoom}</p>
                                                <p>Кол-во: {item.amount}</p>
                                                <p>Логин: {item.login}</p>
                                                <p>Сумма: {item.sum}</p>
                                                <p>Дата: {item.purchase_date}</p>
                                            </div>
                                        )}
                                        {item.type === 'payscrow' && (
                                            <div className={styles.list__item__info}>
                                                <h2 className={styles.list__item__title}>Payscrow!</h2>
                                                <p>Покер-рум: {item.pokerRoom}</p>
                                                <p>Кол-во: {item.amount}</p>
                                                <p>Логин: {item.login}</p>
                                                <p>Курс: {item.course}</p>
                                                <p>Сумма: {item.sum}</p>
                                            </div>
                                        )}
                                        {(item.type === 'base' || item.type === 'hide') && (
                                            <div className={styles.list__item__info}>
                                                <p>Комната: {item.pokerRoom}</p>
                                                <p>Метод оплаты: {item.paymentMethods[0].methodName}</p>
                                                <p>Кол-во: {item.count}</p>
                                                <p>Сумма: {item.sum}</p>
                                                <p>Дата: {item.time}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                    {list.length === 0 && !loading && <div className={styles.no__data}><p className={styles.list__item__name}>Нет заявок</p></div>}
                </div>
            </div >
        </>
    )
}

export default Chips;
