import { useEffect } from "react";
import './styles/global.css';
import { useNavigate } from "react-router-dom";
import AppRouter from "./components/AppRouter/AppRouter";
import { checkAuth } from "./http/admin";
import { setUser } from "./reducers/userReducer";
import { useDispatch } from "react-redux";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = async () => {
    try {
      const data = await checkAuth();
      dispatch(setUser({ role: data?.role, user_id: data?.user_id }));
      data?.role === 'referal' && navigate('/profile');
    } catch (error) {
      console.error('Error fetching user:', error);
      navigate('/authorization');
    }
  }

  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
