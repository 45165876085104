const defaultState = {
    id: 0,
    role: 'admin',
    weight: 0
}

export default function userReducer(state = defaultState, action) {

    switch (action.type) {
        case 'SET_USER':

            const { role, user_id } = action.payload;

            return {
                ...state,
                id: user_id,
                role,
                weight: role === 'admin' ? 2 : role === 'referal' ? 1 : 3
            }

        default:
            return state
    }
}

export const setUser = info => ({ type: 'SET_USER', payload: info });