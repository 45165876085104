import React, { useEffect, useState } from 'react';
import styles from './Profile.module.css';
import Sidebar from '../../components/Sidebar/Sidebar';
import Loader from '../../components/Loader/Loader';
import { MdContentCopy } from "react-icons/md";
import { getProfile, goOutputCash } from '../../http/admin';
import { useSelector } from 'react-redux';
import { IoMdMenu } from "react-icons/io";
import ModalBar from '../../components/ModalBar/ModalBar';
import { enqueueSnackbar } from 'notistack';

const Profile = () => {
    const user = useSelector(state => state.user);

    const [loading, setLoading] = useState(true);
    const [profileInfo, setProfileInfo] = useState(null);
    const [output, setOutput] = useState({
        id: user.id,
        bank: '',
        card: '',
        sum: '',
    });
    const [openModal, setOpenModal] = useState(false);
    const [copy, setCopy] = useState(false);

    useEffect(() => {
        if (user.id !== 0) {
            setOutput({ ...output, id: user.id });
            getProfileInfo(user.id);
        }
    }, [user.id]);

    const getProfileInfo = async (id) => {
        setLoading(true);
        const data = await getProfile(id);
        setProfileInfo(data.data);
        setLoading(false);
    };

    const copyToClipboard = () => {
        if (profileInfo && profileInfo.ref_url) {
            navigator.clipboard.writeText(profileInfo.ref_url)
                .then(() => {
                    setCopy(true);
                    setTimeout(() => setCopy(false), 2000);
                });
        }
    };

    const outputHandler = async () => {
        if (+output.sum > profileInfo.money_at_the_moment) {
            return enqueueSnackbar('Недостаточно средств', { variant: 'error' });
        }

        if (output.bank && output.card && output.sum) {
            enqueueSnackbar('Создание заявки...', { variant: 'info' });
            setProfileInfo(prev => ({ ...prev, money_at_the_moment: prev.money_at_the_moment - parseFloat(output.sum) }));
            const data = await goOutputCash(output);
            setOutput({
                id: user.id,
                sum: '',
                bank: '',
                card: ''
            });

            return data.message ? enqueueSnackbar(data.message, { variant: 'success' }) : enqueueSnackbar(data.error, { variant: 'error' });
        } else {
            enqueueSnackbar('Заполните все поля', { variant: 'error' });
        }
    };

    return (
        <>
            <div className={styles.sidebar}><Sidebar /></div>
            <section className={styles.container}>
                <IoMdMenu className={styles.menu} onClick={() => setOpenModal(true)} />
                <h2 className={styles.title}>Профиль</h2>
                {loading || !profileInfo ? (
                    <div className={styles.loader}><Loader /></div>
                ) : (
                    <div className={styles.profile}>
                        <h2 className={styles.profile__title}>Добрый день! <br />{profileInfo.fio}</h2>
                        <div className={styles.profile__info}>
                            <div className={styles.profile__info__ref}>
                                <span className={styles.profile__info__num}>{profileInfo.all_money} ₽</span>
                                <p className={styles.profile__info__text}>Всего заработано</p>
                            </div>
                            <div className={styles.profile__info__ref}>
                                <span className={styles.profile__info__num}>{profileInfo.money_at_the_moment} ₽</span>
                                <p className={styles.profile__info__text}>Баланс на {new Date().toLocaleDateString()}</p>
                            </div>
                            <div className={styles.profile__info__ref}>
                                <span className={styles.profile__info__num}>{profileInfo.count}</span>
                                <p className={styles.profile__info__text}>Кол-во ваших рефералов</p>
                            </div>
                            <div className={styles.profile__info__ref}>
                                <span className={styles.profile__info__num}>{profileInfo.percent}%</span>
                                <p className={styles.profile__info__text}>Процент за реферала</p>
                            </div>
                            <div className={`${styles.profile__info__ref} ${copy && styles.profile__info__ref__copy}`} onClick={copyToClipboard}>
                                <MdContentCopy className={styles.profile__info__copy} />
                                <p className={styles.profile__info__link}>Ваша реферальная ссылка</p>
                                <p className={styles.profile__info__text}>{profileInfo.ref_url}</p>
                            </div>
                        </div>

                        <div className={styles.profile__info__pay}>
                            <h2 className={styles.title}>Вывод</h2>
                            <div className={styles.profile__info__money}>
                                <div className={styles.reference}>
                                    <h2 className={styles.profile__info__text}>Справка</h2>
                                    <ul className={styles.profile__info__money__list}>
                                        <li>Пользователь переходит по вашей ссылке и становится вашим рефералом</li>
                                        <li>За каждую его покупку вы получаете %</li>
                                        <li>После этого вы можете вывести свой баланс</li>
                                    </ul>
                                </div>
                                <div className={styles.profile__info__money__text}>
                                    <p className={styles.profile__info__text}>Сколько вы хотите вывести?</p>
                                    <input className={styles.input} value={output.bank} onChange={(e) => setOutput({ ...output, bank: e.target.value })} placeholder='Банк' type="text" />
                                    <input className={styles.input} value={output.card} onChange={(e) => setOutput({ ...output, card: e.target.value })} placeholder='Номер карты' type="text" />
                                    <input className={styles.input} value={output.sum} onChange={(e) => setOutput({ ...output, sum: e.target.value })} placeholder='Сумма' type="number" />
                                    <button className={styles.button} onClick={outputHandler}>Подать заявку</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>
            {openModal && <ModalBar setOpenModal={setOpenModal} />}
        </>
    );
};

export default Profile;
