import React, { useEffect, useState } from 'react';
import styles from './Statistics.module.css';
import Sidebar from '../../components/Sidebar/Sidebar';
import { getStatistics } from '../../http/admin';
import Loader from '../../components/Loader/Loader';

const Statistics = () => {

    const [data, setData] = useState();
    const [monthData, setMonthData] = useState();
    const [monthAll, setMonthAll] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getStat();
    }, [])

    const getStat = async () => {
        setLoading(true);
        const data = await getStatistics();
        setData(data.today);
        setMonthData(data.month);
        setMonthAll(data.month_all);
        setLoading(false);
    }


    if (loading) {
        return <>
            <Sidebar />
            <div className={`${styles.container} ${styles.loader}`}>
                <Loader />
            </div>
        </>
    }

    return (
        <>
            <Sidebar />
            <section className={styles.container}>
                <h2 className={styles.title}>
                    Статистика на {new Date().toLocaleDateString()}
                </h2>
                <div className={styles.list__wrapper} id='scrollableDiv'>
                    <table className={styles.statisticsTable}>
                        <thead>
                            <tr>
                                <th>Всего получено</th>
                                <th>Всего отправлено</th>
                                <th>На счету сейчас</th>
                                <th>Комиссия</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{monthAll.today_get} $</td>
                                <td>{monthAll.today_send} $</td>
                                <td>{monthAll.today_money} $</td>
                                <td>{monthAll.today_comission} $</td>
                            </tr>
                        </tbody>
                    </table>

                    <h2 className={styles.month__title}>Статистика за месяц</h2>

                    <table className={styles.statisticsTable}>
                        <thead>
                            <tr>
                                <th>Всего получено</th>
                                <th>Всего отправлено</th>
                                <th>На счету сейчас</th>
                                <th>Комиссия</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{data.today_get} $</td>
                                <td>{data.today_send} $</td>
                                <td>{data.today_money} $</td>
                                <td>{data.today_comission} $</td>
                            </tr>
                        </tbody>
                    </table>

                    <h2 className={styles.history__title} >История</h2>

                    <div className={styles.history}>
                        {monthData.map((item, index) => (
                            <div key={index}>
                                <h3 key={index} className={styles.date__title}>{item.date}</h3>
                                <table className={styles.statisticsTable}>
                                    <thead>
                                        <tr>
                                            <th>Всего получено</th>
                                            <th>Всего отправлено</th>
                                            <th>На счету сейчас</th>
                                            <th>Комиссия</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{item.today_get} $</td>
                                            <td>{item.today_send} $</td>
                                            <td>{item.today_money} $</td>
                                            <td>{item.today_comission} $</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Statistics;
