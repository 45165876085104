import React, { useState } from 'react';
import styles from './Auth.module.css';
import { checkCode, fetchAuth } from '../../http/admin';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { setUser } from '../../reducers/userReducer';

function Auth() {
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');
    const [requestSuccessful, setRequestSuccessful] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = async () => {

        if (phone !== '' && password !== '') {
            setIsLoading(true);
            const data = await fetchAuth(phone, password);
            setIsLoading(false);
            if (data !== undefined) {
                return setRequestSuccessful(true);
            }
            return enqueueSnackbar('Неверный логин или пароль', { variant: 'error' });
        }
        enqueueSnackbar('Заполните все поля', { variant: 'error' });
        setRequestSuccessful(false);
    };

    const handleCodeSubmit = async () => {
        try {
            setIsLoading(true);
            const data = await checkCode(code);

            dispatch(setUser({ role: data.role, user_id: data.user_id }));
            data.role === 'referal' ? navigate('/profile') : navigate('/');
        } catch (error) {
            return enqueueSnackbar('Неверный код', { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <section className={styles.authSection}>
            <div className={styles.authBackground}></div>
            <div className={styles.authContainer}>
                {!requestSuccessful ? (
                    <>
                        <h2 className={styles.title}>Вход</h2>
                        <form className={styles.form}>
                            <div className={styles.formGroup}>
                                <div className={styles.inputGroup}>
                                    <label className={styles.label} htmlFor="phone">Номер телефона</label>
                                    <input
                                        className={styles.input}
                                        id="phone"
                                        type="text"
                                        value={phone}
                                        onChange={e => setPhone(e.target.value)}
                                        placeholder='+7 (___) ___-__-__'
                                    />
                                </div>
                                <div className={styles.inputGroup}>
                                    <label className={styles.label} htmlFor="password">Пароль</label>
                                    <input
                                        className={styles.input}
                                        id="password"
                                        type="password"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        placeholder="********"
                                    />
                                </div>
                            </div>
                            <button
                                className={styles.button}
                                type="button"
                                onClick={handleSubmit}>
                                {isLoading ? 'Загрузка...' : 'Войти'}
                            </button>
                        </form>
                    </>
                ) : (
                    <>
                        <div className={styles.inputGroup}>
                            <h2 className={styles.title}>Введите код</h2>
                            <input
                                type="text"
                                className={styles.input}
                                value={code}
                                onChange={e => setCode(e.target.value)}
                                placeholder="Код из бота: 633648"
                            />
                        </div>
                        <button className={styles.button} type="button" onClick={handleCodeSubmit}>
                            {isLoading ? 'Загрузка...' : 'Войти'}
                        </button>
                    </>
                )}
            </div>

        </section>
    );
}

export default Auth;
