import React, { useEffect, useState } from 'react';
import { changeAdmin, fetchAdmins, fetchSearch } from '../../http/admin'; // Предположим, это ваша функция для изменения статуса
import styles from './AdminList.module.css';
import Sidebar from '../../components/Sidebar/Sidebar';
import Loader from '../../components/Loader/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';

function AdminList() {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [admins, setAdmins] = useState([]);
    const [users, setUsers] = useState([]);
    const [userPasswords, setUserPasswords] = useState({});
    const [loading, setLoading] = useState(true);
    const [isEnd, setIsEnd] = useState(true);

    useEffect(() => {
        getChipsHistory();
    }, [page])

    const handlePasswordChange = (id, pass) => {
        setUserPasswords(prev => ({ ...prev, [id]: pass }));
    };

    const getChipsHistory = async () => {
        setLoading(true);
        const data = await fetchAdmins(page, limit);
        setAdmins(data.admins);
        setUsers([...users, ...data.users]);
        setIsEnd(false);

        if (data.pagination.total_count <= users.length) {
            setIsEnd(true);
        }

        setLoading(false);
    };

    const search = async (type, e) => {
        const data = await fetchSearch(type, e.target.value);

        if (data && type === 'admins') {
            setAdmins(data);
        }

        if (data && type === 'users') {
            setUsers(data);
        }
    }

    const handleChange = async (id, status, user) => {

        const pass = userPasswords[id] || '';

        if (status) {
            setAdmins([...admins, { ...user, pass }]);
            setUsers(users.filter(user => user.id !== id));
        } else {
            setUsers([...users, { ...user, pass }]);
            setAdmins(admins.filter(admin => admin.id !== id));
        }
        await changeAdmin(id, status, pass);
    };

    return (
        <>
            <Sidebar />

            <div className={styles.container}>
                <>
                    <div className={styles.search}>
                        <h2 className={styles.title}>Администраторы</h2>
                        <input
                            type="text"
                            className={styles.input}
                            placeholder='Поиск по айди'
                            onChange={(e) => search('admins', e)}
                        />
                    </div>
                    <div className={styles.admin__list}>
                        <ul className={styles.admins} >
                            {
                                admins.map((admin) =>
                                    <li className={styles.list__item} key={admin.id}>
                                        <div className={styles.list__item__info}>
                                            <div className={styles.info__wrapper}>
                                                <p className={styles.username}>{admin.id} ({admin.full_name || 'Не найдено'})</p>
                                                <p>Пароль:</p>
                                                <input value={admin.pass} className={styles.password__input} type="text" readOnly={true} />
                                            </div>
                                            <button className={styles.delete} onClick={() => handleChange(admin.id, false, admin)}>Удалить</button>
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                        {/* {loading && <div className={styles.user__loader}><Loader /></div>} */}
                        {admins.length === 0 && !loading && <div><p className={styles.no__data}>Нет данных...</p></div>}
                    </div>

                    <hr className={styles.hr} />

                    <div className={styles.search}>
                        <h2 className={styles.title}>Пользователи</h2>
                        <input
                            type="text"
                            className={styles.input}
                            placeholder='Поиск по айди'
                            onChange={(e) => search('users', e)}
                        />
                    </div>

                    <div className={styles.list__wrapper}>
                        <div className={styles.list} id="scrollableDiv2">
                            <InfiniteScroll
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                                    listStyle: 'none',
                                    gap: '10px',
                                }}
                                scrollableTarget="scrollableDiv2"
                                next={() => setPage(prevPage => prevPage + 1)}
                                dataLength={users.length}
                                hasMore={!isEnd}
                                loader={<h4>Загрузка...</h4>}>
                                {users.map((user) =>
                                    <div className={styles.list__item} key={user.id}>
                                        <div className={styles.list__item__info}>
                                            <div className={styles.info__wrapper}>
                                                <p className={styles.username}>{user.id} ({user.full_name || 'Не найдено'})</p>
                                                <p>Задайте пароль:</p>
                                                <input
                                                    className={styles.password__input}
                                                    type="text"
                                                    placeholder='Пароль'
                                                    onChange={(e) => handlePasswordChange(user.id, e.target.value)}
                                                />
                                            </div>
                                            <button className={styles.add} onClick={() => handleChange(user.id, true, user)}>Добавить</button>
                                        </div>
                                    </div>
                                )}
                            </InfiniteScroll>
                            {loading && <div className={styles.user__loader}><Loader /></div>}
                            {users.length === 0 && !loading && <div><p className={styles.no__data}>Нет данных...</p></div>}
                        </div>
                    </div>
                </>
            </div>
        </>
    );
}

export default AdminList;
