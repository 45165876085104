import { useEffect, useState, useCallback } from 'react';
import styles from './ChipItem.module.css';
import { fetchChipItem, fetchChipCompletes } from '../../http/admin';
import Loader from '../../components/Loader/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import { IoCopyOutline } from 'react-icons/io5';
import { IoMdArrowBack } from 'react-icons/io';
import { enqueueSnackbar } from 'notistack';

function ChipItem() {
    const { id, type } = useParams();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Fetch user info function with better error handling and retry mechanism if needed
    const fetchUserInfo = useCallback(async (id) => {
        setLoading(true);
        try {
            const data = await fetchChipItem(id);
            if (data) {
                setUserData(data);
            } else {
                enqueueSnackbar('Нет данных для отображения', { variant: 'warning' });
            }
        } catch (error) {
            console.error('Failed to fetch user info:', error);
            enqueueSnackbar('Ошибка загрузки данных', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (id) {
            fetchUserInfo(id);
        }
    }, [id, fetchUserInfo]);

    // Copy to clipboard function with snackbar
    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            enqueueSnackbar('Скопировано в буфер обмена', { variant: 'success' });
        } catch (error) {
            console.error('Failed to copy to clipboard:', error);
            enqueueSnackbar('Ошибка при копировании', { variant: 'error' });
        }
    };

    // Function to handle result submission with better error handling
    const handleResult = async (info) => {
        enqueueSnackbar('Загрузка...', { variant: 'info' });
        try {
            const data = await fetchChipCompletes(userData._id, userData.type, info);
            if (data.message) {
                enqueueSnackbar(data.message, { variant: 'success' });
                navigate('/admin-chips');
            } else if (data.error) {
                enqueueSnackbar(data.error, { variant: 'error' });
            } else {
                enqueueSnackbar('Неизвестная ошибка', { variant: 'error' });
            }
        } catch (error) {
            console.error('Failed to handle result:', error);
            enqueueSnackbar('Ошибка отправки данных', { variant: 'error' });
        }
    };

    // Render fields helper function
    const renderField = (label, value, isBold = false, copyable = false) => (
        <div className={styles.field}>
            <p className={isBold ? styles.text__bold : styles.text}>{label}:</p>
            <p className={isBold ? styles.text__bold : styles.text}>{value}</p>
            {copyable && <IoCopyOutline className={styles.copy__icon} onClick={() => copyToClipboard(value)} />}
        </div>
    );

    if (loading) {
        return (
            <div className={styles.loader}>
                <Loader />
            </div>
        );
    }

    // Conditional rendering based on the type
    return (
        <>
            <Sidebar />
            <div className={`${styles.container} ${styles[`type-${type}`]}`}>
                <IoMdArrowBack onClick={() => navigate(-1)} className={styles.back} />
                <h2 className={styles.title}>Перевод фишек</h2>
                <div className={styles.info}>
                    {userData && (
                        <>
                            {type === 'check_pay' && (
                                <>
                                    {renderField('Логин', userData.login, true, true)}
                                    {renderField('Сумма', userData.sum, true, true)}
                                    {renderField('Покер Рум', userData.pokerRoom)}
                                    {renderField('Кол-во', userData.amount)}
                                    {renderField('Дата', userData.date)}
                                </>
                            )}
                            {type === 'payscrow' && (
                                <>
                                    {renderField('Логин', userData.login, true, true)}
                                    {renderField('Покер Рум', userData.pokerRoom)}
                                    {renderField('Кол-во', userData.amount)}
                                    {renderField('Курс', userData.course)}
                                    {renderField('Сумма', userData.sum)}
                                </>
                            )}
                            {(type !== 'check_pay' && type !== 'payscrow') && (
                                <>
                                    {renderField('Айди', userData._id)}
                                    {type !== 'go_out_referral' && renderField('Логин', userData.login, true, true)}
                                    {renderField('Сумма', userData.sum, true, true)}
                                    {type !== 'delete' && renderField('Детали оплаты', type === 'go_out_referral' ? userData.number_card : userData.paymentMethods[0]?.details, true, true)}
                                    {type !== 'delete' && renderField('Банк', userData.bank)}
                                    {type !== 'go_out_referral' && renderField('Покер Рум', userData.pokerRoom)}
                                    {type !== 'delete' && type !== 'go_out_referral' && renderField('Способ оплаты', userData.paymentMethods[0]?.methodName)}
                                    {type !== 'delete' && type !== 'go_out_referral' && renderField('Количество', userData.count)}
                                    {type !== 'delete' && renderField('Дата', type === 'go_out_referral' ? userData.purchase_date : userData.time)}
                                </>
                            )}
                        </>
                    )}
                </div>
                <div className={styles.buttons}>
                    {type !== 'delete' ? (
                        <div className={styles.btn__wrapper}>
                            <button onClick={() => handleResult(false)} className={`${styles.btn} ${styles.btn__decline}`}>
                                Отклонить
                            </button>
                            <button onClick={() => handleResult(true)} className={`${styles.btn} ${styles.btn__accept}`}>
                                Отправил
                            </button>
                        </div>
                    ) : (
                        <button onClick={() => handleResult(true)} className={`${styles.btn} ${styles.btn__accept}`}>
                            Отменить
                        </button>
                    )}
                </div>
            </div>
        </>
    );
}

export default ChipItem;
