export function getCsrfTokenFromCookie(cookieName) {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Проверяем, начинается ли cookie с имени, которое мы ищем
        if (cookie.startsWith(cookieName + '=')) {
            return cookie.substring((cookieName.length + 1), cookie.length);
        }
    }
    // Если cookie не найдено, вернём null
    return null;
}