import React from 'react'
import styles from './ModalBar.module.css'
import { IoMdMenu } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

const ModalBar = ({ setOpenModal }) => {

    const navigate = useNavigate();

    return (
        <div className={styles.modal}>
            <div className={styles.modal__container}>
                <div className={styles.modal__header}>
                    <h2 className={styles.modal__title}>Панель</h2>
                    <IoMdMenu className={styles.menu} onClick={() => setOpenModal(false)} />
                </div>
                <ul className={styles.modal__bar}>
                    <li onClick={() => setOpenModal(false)}>Профиль</li>
                    <li onClick={() => navigate('/authorization')}>Выход</li>
                </ul>
            </div>
        </div>
    )
}

export default ModalBar