import React, { useEffect, useState } from 'react'
import styles from './Refferal.module.css'
import { motion } from 'framer-motion'
import { refferalDelete, refferalSave, searchRefferal } from '../../http/admin';
import { useSnackbar } from 'notistack';
import { IoMdClose } from "react-icons/io";

const Refferal = ({ refferals }) => {

    const [originalRefferals, setOriginalRefferals] = useState(refferals);
    const [updateRefferal, setUpdateRefferal] = useState(refferals);
    const [refferalLink, setRefferalLink] = useState('');
    const [percent, setPercent] = useState('');
    const [search, setSearch] = useState('');
    const [refferalId, setRefferalId] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (refferalId !== '') {
            setRefferalLink(`https://t.me/test_botech_bot?start=${refferalId}`);
        } else {
            setRefferalLink('');
        }
    }, [refferalId])

    const copyToClipboard = async () => {
        navigator.clipboard.writeText(refferalLink || '');
        const data = await refferalSave(refferalId, percent);

        if (data) {
            enqueueSnackbar('Успешно сохранено!', { variant: 'success' });
            setUpdateRefferal(prevRefferals => [...prevRefferals, { id: refferalId, username: data.fullname }]);
            setOriginalRefferals(prevRefferals => [...prevRefferals, { id: refferalId, username: data.fullname }]);
        } else {
            enqueueSnackbar('Произошла ошибка', { variant: 'error' });
        }
    }

    const handleDelete = async (id) => {
        const data = await refferalDelete(id);
        if (data) {
            enqueueSnackbar('Успешно удалено!', { variant: 'success' });
            setUpdateRefferal(prevRefferals => prevRefferals.filter(referal => referal.id !== id));
            setOriginalRefferals(prevRefferals => prevRefferals.filter(referal => referal.id !== id));
        } else {
            enqueueSnackbar('Произошла ошибка', { variant: 'error' });
        }
    }

    const handleSearch = async () => {
        if (search !== '') {
            const data = await searchRefferal(search);

            if (data.fullname || data.id) {
                setUpdateRefferal([{ username: data.fullname, id: data.id }]);
                enqueueSnackbar('Успешно найдено!', { variant: 'success' })
            } else {
                enqueueSnackbar('Не найдено!', { variant: 'info' })
            }
        }
    }

    const handleOff = () => {
        setSearch('');
        setUpdateRefferal(originalRefferals);
    }

    return (
        <>

            <div className={styles.wrapper}>

                <div className={styles.container}>
                    <div className={styles.refferal}>
                        <p className={styles.title}>Поиск по айди</p>
                        <div className={styles.inputs}>
                            <input value={search} onChange={(e) => setSearch(e.target.value)} className={styles.input} placeholder='4927419232374' type="number" />
                            <motion.button whileTap={{ scale: 0.9 }} className={styles.button} onClick={() => handleSearch()}>Найти</motion.button>
                            <motion.button whileTap={{ scale: 0.9 }} className={styles.button} onClick={() => handleOff()}>Cбросить</motion.button>
                        </div>
                    </div>
                </div>

                <div className={styles.container}>
                    <div className={styles.refferal}>
                        <p className={styles.title}>Добавить реферала</p>
                        <div className={styles.inputs}>
                            <input value={refferalId} onChange={(e) => setRefferalId(e.target.value)} className={styles.input} placeholder='4927419232374' type="number" />
                            <input className={styles.input} value={percent} onChange={(e) => setPercent(e.target.value)} type="number" placeholder='Процент за одного: _%' />
                            <input type="text" value={refferalLink} className={styles.input} placeholder='https://t.me/test_botech_bot?start=' readOnly={true} />
                            <motion.button whileTap={{ scale: 0.9 }} className={styles.button} onClick={() => copyToClipboard()}>Сохранить и скопировать</motion.button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.refferal__list}>
                <p className={styles.title}>Список рефералов</p>
                <ul className={styles.list}>
                    {updateRefferal.length > 0 ? updateRefferal.map((referal) =>
                        <li className={styles.list__item} key={referal.id}>
                            <div>
                                <p>{referal.id}</p>
                                <p>{referal.username}</p>
                            </div>
                            <div className={styles.delete} onClick={() => handleDelete(referal.id)}>
                                <IoMdClose className={styles.close} />
                            </div>
                        </li>
                    ) : <p className={styles.no__data}>Нет данных...</p>}
                </ul>
            </div>
        </>
    )
}

export default Refferal
