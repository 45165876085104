export const ADMIN_IDENTIFICATION = '/identification-item/:id'
export const ADMIN_IDENTIFICATION__LIST = '/'
export const ADMIN_CHIPS = '/admin-chips'
export const ADMIN_CHIP_ITEM = '/admin-chip-item/:id/:type'
export const AUTH = '/authorization'
export const CHIP_HISTORY = '/chip-history'
export const CHIP_HISTORY__ITEM = '/chip-history-item/:id'
export const ADMIN_LIST = '/admin-list'
export const ADD_ADMIN = '/add-admin'
export const SETTINGS = '/settings'
export const PAY_BEFORE = '/pay-before'
export const RESZISE = '/resize'
export const PROFILE = '/profile'
export const PEOPLE__LIST = '/people-list'
export const PEOPLE__ITEM = '/people-item/:id'
export const STATISTICS = '/statistics'
export const CREATE_APPLICATION = '/create-application'