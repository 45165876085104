import React, { useEffect, useState } from 'react'
import Loader from '../../components/Loader/Loader'
import Sidebar from '../../components/Sidebar/Sidebar'
import styles from './PeopleItem.module.css'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchChipCompletes, getUserInfo } from '../../http/admin'
import { IoMdArrowBack } from "react-icons/io";
import InfiniteScroll from 'react-infinite-scroll-component'
import { enqueueSnackbar } from 'notistack';

const PeopleItem = () => {

    const { id } = useParams();
    const [user, setUser] = useState(null);
    const [applications, setApplications] = useState([]);
    const [fetchingList, setFetchingList] = useState(true);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [search, setSearch] = useState('');
    const [total, setTotal] = useState(0);
    const [isEnd, setIsEnd] = useState(true);

    const navigate = useNavigate();

    const [isInitialLoad, setIsInitialLoad] = useState(true);

    useEffect(() => {
        fetchUserData();
        setIsInitialLoad(false);
    }, []);

    useEffect(() => {
        if (search === '' && !isInitialLoad) {
            setPage(1);
            setApplications([]);
            fetchUserData();
        }
    }, [search]);

    const fetchUserData = async () => {
        const data = await getUserInfo(id, page, limit, search);
        setUser(data.data);
        const newApplications = data.applications;
        if (newApplications.length === 0) setIsEnd(true);
        else {
            setIsEnd((page - 1) * limit + newApplications.length >= data.total_count);
            setApplications(prev => search ? newApplications : [...prev, ...newApplications]);
        }
        setFetchingList(false);
        setTotal(data.total_count);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setPage(1);
            setApplications([]);
            fetchUserData();
        }
    }

    const cancelApplication = async (id) => {
        setApplications(prev => prev.filter(item => item._id !== id));
        enqueueSnackbar('Обработка...', { variant: 'info' });
        const data = await fetchChipCompletes(id, 'delete', true);
        if (data.status === 'success') {
            enqueueSnackbar(data.message, { variant: 'success' });
        } else {
            enqueueSnackbar(data.error, { variant: 'error' });
        }
    }

    return (
        <>
            <Sidebar />
            <section className={styles.container}>
                <IoMdArrowBack onClick={() => navigate(-1)} className={styles.back} />
                <h2 className={styles.title}>
                    Информация
                </h2>
                <div className={styles.list__wrapper}>
                    <div className={styles.info__container}>
                        <div className={styles.info__wrapper}>
                            <h3 className={styles.info__title}>Общая информация</h3>
                            {fetchingList ? <div className={styles.info__loader}><Loader /></div> : (
                                <div className={styles.info}>
                                    <p>ФИО: {user.full_name}</p>
                                    <p>Айди: {user.id}</p>
                                    <p>Номер телефона: {user.phone}</p>
                                    <p>Роль: {user.role}</p>
                                </div>
                            )}
                        </div>

                        <div className={styles.info__wrapper}>
                            <h3 className={styles.info__title}>Идентификация</h3>
                            {fetchingList ? <div className={styles.info__loader}><Loader /></div> : (
                                <div className={styles.list} id="scrollableDiv">
                                    {user.status_ident.map((item, index) => (
                                        <div key={index}>
                                            <p>Название: {item.name}</p>
                                            <p>Email/Login: {item.email || 'Пусто'}</p>
                                            <p>Статус: {item.is_check ? 'Подтверждено' : item.check_it ? 'Ожидает подтверждения' : 'Не подтверждено'}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={styles.card__list__wrapper} id="scrollableDiv">
                        <div className={styles.card__list}>
                            <h3 className={styles.info__title}>Все заявки</h3>
                            <div className={styles.input__wrapper}>
                                <input type="text" className={styles.input} value={search}
                                    onChange={(e) => setSearch(e.target.value)} onKeyPress={handleKeyPress} placeholder='Поиск по айди' />
                                <IoMdArrowBack className={styles.enter} onClick={() => search && fetchUserData()} />
                            </div>

                            <InfiniteScroll
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                                    listStyle: 'none',
                                    gap: '10px',
                                }}
                                scrollableTarget="scrollableDiv"
                                next={() => setPage(prevPage => prevPage + 1)}
                                dataLength={applications.length}
                                hasMore={!isEnd}
                                loader={<h4>Loading...</h4>}>
                                {applications.length > 0 && applications.map((item, index) => (
                                    item.type !== 'base' ? (
                                        <li className={`${styles.list__item} ${item.status === 'Завершено' ? styles.list__item__complete : styles.list__item__process}`} key={index}>
                                            <h4 className={styles.list__item__title}>{item.prodacid === user.id ? 'ПРОДАЖА' : 'ПОКУПКА'}</h4>
                                            <p>Айди: {item._id}</p>
                                            <p>Покер-рум: {item.pokerRoom}</p>
                                            <p>Метод оплаты: {item.bank || 'НЕ ОПЛАЧЕН'}</p>
                                            <p>Курс: {item.course}</p>
                                            <p>Кол-во: {item.count}</p>
                                            <p>Время: {item.time || 'НЕ ОПЛАЧЕНО'}</p>
                                            <p>Статус: {item.status}</p>
                                        </li>
                                    ) : (
                                        <li className={styles.list__item} key={index}>
                                            <h4 className={styles.list__item__title}>АКТУАЛЬНАЯ ЗАЯВКА</h4>
                                            <p>Айди: {item._id}</p>
                                            <p>Покер-рум: {item.pokerRoom}</p>
                                            <p>Методы оплаты: {item.paymentMethods.map(method => method.methodName).join(', ')}</p>
                                            <p>Курс: {item.course}</p>
                                            <p>Кол-во: {item.quantity}</p>
                                            <p>Макс-мин: {item.max} - {item.min}</p>
                                            <p>Время: {item.time || 'НЕТ'}</p>
                                            <button className={styles.button} onClick={() => cancelApplication(item._id)}>Удалить заявку</button>
                                        </li>
                                    )
                                ))}
                            </InfiniteScroll>

                            {fetchingList && !isInitialLoad && (
                                <div className={styles.loader}><Loader /></div>
                            )}
                            {applications.length === 0 && !fetchingList && isInitialLoad && <div className={styles.no__history}>Нет истории заявок</div>}
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default PeopleItem