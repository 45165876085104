import React, { useEffect, useState } from 'react'
import styles from './AcceptList.module.css'
import { useNavigate } from 'react-router-dom'
import { getIdentList } from '../../http/admin';
import Loader from '../../components/Loader/Loader';
import Sidebar from '../../components/Sidebar/Sidebar';

function AcceptList() {

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const getList = async () => {
            setLoading(true);
            try {
                const data = await getIdentList();
                setList(data.users);
            } catch (error) {
                console.error('Error fetching identification list:', error);
            }
            setLoading(false);
        };

        getList();

        const intervalId = setInterval(() => {
            getList();
        }, 20000);

        return () => clearInterval(intervalId);
    }, [])

    return (
        <>
            <Sidebar />
            <div className={styles.container}>

                <h2 className={styles.title}>Список заявок</h2>

                <div className={styles.list__wrapper}>
                    <ul className={styles.list__container} id='scrollableDiv'>
                        {!loading && list.map(item =>
                            <li className={styles.list__item} key={item.id + item.ident_id} onClick={() => navigate(`/identification-item/${item.ident_id}`)}>
                                <div className={styles.list__item__icon}>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="Navbar_profile__icon__-vWlz" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg>
                                </div>

                                <div className={styles.list__item__info__wrapper}>
                                    <div className={styles.list__item__info}>
                                        <p className={styles.list__item__name}>{item.lastName} {item.firstName} {item.middleName}</p>
                                    </div>

                                    <div className={styles.list__item__sub__info}>
                                        <p>{item.pokerRoom}</p>
                                        <p>{item.phone}</p>
                                    </div>
                                </div>
                            </li>)
                        }
                    </ul>
                    {loading && <div className={styles.loader}><Loader /></div>}
                    {list.length === 0 && !loading && <div className={styles.no__data}><p className={styles.list__item__name}>Нет заявок</p></div>}
                </div>
            </div>
        </>
    )
}

export default AcceptList
